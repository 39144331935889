import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

class GalleryPage extends Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src =
        'https://cdnjs.cloudflare.com/ajax/libs/lightgallery-js/1.4.0/js/lightgallery.min.js';
      document.body.appendChild(script);

      const lightGallery = document.createElement('script');
      lightGallery.text = "lightGallery(document.getElementById('lightgallery'));";
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Bagoly-Lak - Galéria</title>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/lightgallery-js/1.4.0/css/lightgallery.min.css"
          />
        </Helmet>
        <div className="main">
          <img className="main__background-full" src="assets/background-full.png" alt="Háttérkép" />
          <img
            className="main__background-minimal"
            src="assets/background-minimal.png"
            alt="Háttérkép"
          />
          <img className="main__logo" src="assets/bagoly-lak-logo.png" alt="Logo" />
          <div className="main__menu main__menu--desktop">
            <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
            <div className="main__menu__menus">
              <Link to="/">Bemutató</Link>
              <Link to="/matraszentimre">Mátraszentimre</Link>
              <Link to="/galeria">Galéria</Link>
              <Link to="/hasznos-informaciok">Hasznos információk</Link>
              <Link to="/szabadidos-programok">Szabadidős programok</Link>
              <Link to="/elerhetoseg">Elérhetőség</Link>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="main__menu main__menu--mobile">
                  <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
                  <div className="main__menu__menus">
                    <Link to="/">Bemutató</Link>
                    <Link to="/matraszentimre">Mátraszentimre</Link>
                    <Link to="/galeria">Galéria</Link>
                    <Link to="/hasznos-informaciok">Hasznos információk</Link>
                    <Link to="/szabadidos-programok">Szabadidős programok</Link>
                    <Link to="/elerhetoseg">Elérhetőség</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="gallery">
                    <h1>Galéria</h1>
                    <div id="lightgallery">
                      <a href="assets/gallery/1.jpg">
                        <img src="assets/gallery/thumbnails/1.jpg" alt="1" />
                      </a>
                      <a href="assets/gallery/2.jpg">
                        <img src="assets/gallery/thumbnails/2.jpg" alt="2" />
                      </a>
                      <a href="assets/gallery/3.jpg">
                        <img src="assets/gallery/thumbnails/3.jpg" alt="3" />
                      </a>
                      <a href="assets/gallery/4.jpg">
                        <img src="assets/gallery/thumbnails/4.jpg" alt="4" />
                      </a>
                      <a href="assets/gallery/5.jpg">
                        <img src="assets/gallery/thumbnails/5.jpg" alt="5" />
                      </a>
                      <a href="assets/gallery/6.jpg">
                        <img src="assets/gallery/thumbnails/6.jpg" alt="6" />
                      </a>
                      <a href="assets/gallery/7.jpg">
                        <img src="assets/gallery/thumbnails/7.jpg" alt="7" />
                      </a>
                      <a href="assets/gallery/8.jpg">
                        <img src="assets/gallery/thumbnails/8.jpg" alt="8" />
                      </a>
                      <a href="assets/gallery/9.jpg">
                        <img src="assets/gallery/thumbnails/9.jpg" alt="9" />
                      </a>
                      <a href="assets/gallery/10.jpg">
                        <img src="assets/gallery/thumbnails/10.jpg" alt="10" />
                      </a>
                      <a href="assets/gallery/11.jpg">
                        <img src="assets/gallery/thumbnails/11.jpg" alt="11" />
                      </a>
                      <a href="assets/gallery/12.jpg">
                        <img src="assets/gallery/thumbnails/12.jpg" alt="12" />
                      </a>
                      <a href="assets/gallery/13.jpg">
                        <img src="assets/gallery/thumbnails/13.jpg" alt="13" />
                      </a>
                      <a href="assets/gallery/14.jpg">
                        <img src="assets/gallery/thumbnails/14.jpg" alt="14" />
                      </a>
                      <a href="assets/gallery/15.jpg">
                        <img src="assets/gallery/thumbnails/15.jpg" alt="15" />
                      </a>
                      <a href="assets/gallery/16.jpg">
                        <img src="assets/gallery/thumbnails/16.jpg" alt="16" />
                      </a>
                      <a href="assets/gallery/17.jpg">
                        <img src="assets/gallery/thumbnails/17.jpg" alt="17" />
                      </a>
                      <a href="assets/gallery/18.jpg">
                        <img src="assets/gallery/thumbnails/18.jpg" alt="18" />
                      </a>
                      <a href="assets/gallery/19.jpg">
                        <img src="assets/gallery/thumbnails/19.jpg" alt="19" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main__separator" />
        </div>
      </>
    );
  }
}

export default GalleryPage;
